import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import useForm from '../../_common/_Hooks/useForm'
import useTranslate from '../../_common/_Hooks/useTranslate'
import ArrayCollector from '../../_common/_components/ArrayCollector/ArrayCollector'
import Button, { Buttons } from '../../_common/_components/Button/Button'
import Input from '../../_common/_components/Input/Input'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import Flex from '../../_common/_components/Flex/Flex'

const AddEditWebIncidentTypes = () => {

    const id = useParams()
    const { t } = useTranslate()

    const [page, setPage] = useState(0)

    const [subsubtask, setSubsubtask] = useState()

    const { formPlug, inputs, inputHandler, setInputs } = useForm({
        subtypes: [
            {
                "sub_incident_type_en": "cvbcxxb",
                "sub_incident_type_ar": "xcvbxcvb",
            },
            {
                "sub_incident_type_en": "xcbxc",
                "sub_incident_type_ar": "xcvb",
            },
            {
                "sub_incident_type_en": "xcvbxc",
                "sub_incident_type_ar": "xcbxcvb",
            },
            {
                "sub_incident_type_en": "xcvbxcvb",
                "sub_incident_type_ar": "xcvbxcvb",
            }
        ],
        subsubTypes: {
            0: [
                {
                    "sub_incident_type_en": "vbncvb",
                    "sub_incident_type_ar": "cvbn"
                }
            ],
            1: [
                {
                    "sub_incident_type_en": "vbncvb",
                    "sub_incident_type_ar": "cvbn"
                },
                {
                    "sub_incident_type_en": "xcbxcvbnc",
                    "sub_incident_type_ar": "cvbn"
                },
                {
                    "sub_incident_type_en": "cvbn",
                    "sub_incident_type_ar": "cvbnmnvbmn"
                }
            ],
            2: [
                {
                    "sub_incident_type_en": "vbncvb",
                    "sub_incident_type_ar": "cvbn"
                },
                {
                    "sub_incident_type_en": "xcbxcvbnc",
                    "sub_incident_type_ar": "cvbn"
                },
                {
                    "sub_incident_type_en": "xcbxcvbnc",
                    "sub_incident_type_ar": "cvbn"
                },
                {
                    "sub_incident_type_en": "cvbn",
                    "sub_incident_type_ar": "cvbnmnvbmn"
                },
                {
                    "sub_incident_type_en": "cvbn",
                    "sub_incident_type_ar": "cvbnmnvbmn"
                }
            ],
            3: [
                {
                    "sub_incident_type_en": "vbncvb",
                    "sub_incident_type_ar": "cvbn"
                },
                {
                    "sub_incident_type_en": "cvbn",
                    "sub_incident_type_ar": "cvbnmnvbmn"
                }
            ]
        }
    })

    function submitHandler() {

    }

    console.log({ inputs })

    return (
        <Page>

            <Page.Header>
                <Title title='Incident Types' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body>

                <TitleBar css={1} title={`${id ? 'Edit' : 'Add'} Incident Type`} toggleButton>
                    
                    <Flex columns={2} gap={20} width={'50%'}>
                        <Input css={1} mb={20} placeholder='Enter Incident Type' label='Incident Type [En]' field='incident_type_en' required inputSocket={formPlug} />
                        <Input css={1} placeholder='Enter Incident Type' label='Incident Type [Ar]' field='incident_type_ar' required inputSocket={formPlug} />
                    </Flex>
                    <hr />
                    <h6>Sub Types</h6>
                    <Flex gap={30}>
                        <ArrayCollector
                            width={600}
                            value={inputs.subtypes}
                            elements={[
                                { key: 'sub_incident_type_en', title: 'English' },
                                { key: 'sub_incident_type_ar', title: 'Arabic' },
                            ]}
                            onChange={(e) => { inputHandler(e, 'subtypes') }}
                            extraIcons={[{
                                src: '/assets/icons/plus_black.svg',
                                onClick: (index) => setSubsubtask(index)
                            }]}
                            active={subsubtask}
                        />
                        {subsubtask !== undefined && <ArrayCollector
                            width={600}
                            value={inputs.subsubTypes?.[subsubtask]}
                            elements={[
                                { key: 'sub_incident_type_en', title: 'English' },
                                { key: 'sub_incident_type_ar', title: 'Arabic' },
                            ]}
                            onChange={(e) => {
                                console.log({ e })
                                setInputs(s => ({ ...s, subsubTypes: inputs.subsubTypes[subsubtask] = [e] }))
                            }}
                        />}
                    </Flex>
                </TitleBar>

                <Buttons end mt={20}>
                    <Button content='Cancel' css={1} onClick={() => { }} />
                    <Button content='Submit' css={1.1} onClick={submitHandler} />
                </Buttons>

            </Page.Body>
        </Page>
    )
}

export default AddEditWebIncidentTypes
